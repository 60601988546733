/* JamesW - 21st February 2023
  The FeatureFlag interface could be extended to take user permissions into account.
*/

interface FeatureFlag {
  name: string;
  stores?: number[];
}

interface FeatureFlags {
  [key: string]: {
    [key: string]: FeatureFlag[] | undefined;
  };
}

/* 
Production storeIDs
DE:
Hamburg - 1281
Mannheim - 2298
Wuerzburg - 1873
UK:
  Bournemouth - 90
  Leeds - 27
  Poole - 1
*/

/* For more information on the deleteReportFunctionality flag, please see Jira Ticket - LPC-1216 */

const featureFlags: FeatureFlags = {
  production: {
    DE: [{ name: 'pettyCashReceipts' }, { name: 'hideDailyTarget' }],
    GB: [{ name: 'stytchM2MCredentials' }],
    JP: [{ name: 'deleteReportFunctionality' }],
    US: [{ name: 'hideDailyTarget' }, { name: 'singlePersonEod' }],
    CA: [{ name: 'hideDailyTarget' }, { name: 'singlePersonEod' }],
    global: [{ name: 'clarity' }]
  },
  staging: {
    DE: [{ name: 'pettyCashReceipts' }, { name: 'JWBerlinStore', stores: [3114] }, { name: 'hideDailyTarget' }],
    GB: [{ name: 'pettyCashReceipts' }, { name: 'stytchM2MCredentials' }],
    JP: [{ name: 'pettyCashReceipts' }, { name: 'deleteReportFunctionality' }],
    US: [{ name: 'hideDailyTarget' }, { name: 'singlePersonEod' }],
    CA: [{ name: 'hideDailyTarget' }, { name: 'singlePersonEod' }],
    global: [{ name: 'clarity' }]
  },
  development: {
    DE: [
      { name: 'DETestFlag' },
      { name: 'pettyCashReceipts' },
      { name: 'JWBerlinStore', stores: [3114] },
      { name: 'hideDailyTarget' }
    ],
    GB: [{ name: 'UKTestFlag' }, { name: 'pettyCashReceipts' }, { name: 'stytchM2MCredentials' }],
    JP: [{ name: 'pettyCashReceipts' }, { name: 'deleteReportFunctionality' }],
    US: [{ name: 'hideDailyTarget' }, { name: 'singlePersonEod' }],
    CA: [{ name: 'hideDailyTarget' }, { name: 'singlePersonEod' }],
    global: [{ name: 'clarity' }]
  }
};

export function getFeatureFlags(storeId, countryCode) {
  const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV;
  const countryFlags = featureFlags[ENV]?.[countryCode] || [];
  const globalFlags = featureFlags[ENV]?.global || [];
  const availableFlags = [...countryFlags, ...globalFlags].filter((flag) => {
    if (flag.stores && flag.stores?.length > 0) {
      return flag.stores.includes(storeId);
    }
    return true;
  });
  return availableFlags.map((flag) => flag.name);
}

export default featureFlags;
